import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Button, Card, Descriptions, Dropdown, Empty, Form, Select, Spin, Table } from 'antd';
import { EllipsisOutlined, LoadingOutlined } from 'antd/icons';
import qs from 'vl-common/src/lib/query-string';
import * as actions from '@actions';
import * as actionTypes from '@actionTypes';
import useWidthBreakpoint from 'vl-common/src/hooks/useWidthBreakpoint';
import useReactiveColumns from 'vl-common/src/hooks/useReactiveColumns';
import usePagedQuery from '@hooks/usePagedQuery';
import useColumnFilters from 'vl-common/src/hooks/useColumnFilters';
import Pagination from '@components/UI/Table/Pagination';
import Datestamp from 'vl-common/src/components/Datestamp';
import { AppointmentContext } from 'vl-common/src/lib/context/Appointment';
import useGrabAppointment from '@hooks/useGrabAppointment';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import useAuth from '@vl-core/useAuth';
import {
  ConfirmationModal,
  ErrorModal,
  InfoModal,
  confirmationModal,
  errorModal,
  successModal
} from 'vl-common/src/components/Modals';
import styled from '@emotion/styled';
import type { AppointmentSecurity } from 'vl-common/src/schemas/getCliniciansApptSec';

const PatientInfo = dynamic(() => import('@components/Modal/PatientInfo'));

const { Option } = Select;

export function CancelAppointmentModal({
  onCancelAppointment,
  appointment,
  onAppointmentCancelError = (msg) => {},
  cancelModalVisible,
  setCancelModalVisible
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [cancelReasonList, setCancelReasonList] = useState([]);
  const [cancelReason, setCancelReason] = useState({
    reason: undefined,
    user_guid: undefined,
    appt_id: undefined
  });

  const displayModal = useCallback(async () => {
    if (appointment?.letter_saved || appointment?.appointment_outcome !== null) {
      onAppointmentCancelError('Either a letter or an outcome has been saved for this appointment.');
      return;
    }
    const responseMeta = await dispatch(actions.rescheduleCancelMeta());
    if (responseMeta) {
      setCancelReasonList(responseMeta.payload[0].appt_cancel_reasons);
      setCancelReason({
        user_guid: user.user_guid,
        appt_id: appointment.appointment_id.toString(),
        reason: undefined
      });
      setCancelModalVisible(true);
    }
  }, [appointment?.appointment_id, dispatch, user?.user_guid, appointment?.onAppointmentCancelError]);

  const handleCancel = async (cancelReason) => {
    await onCancelAppointment(cancelReason);
    setCancelModalVisible(false);
  };

  useEffect(() => {
    if (appointment) {
      displayModal().then();
    }
  }, [appointment, displayModal]);

  return (
    <ConfirmationModal
      data-testid="cancel-appointment-modal"
      title="Cancel Appointment"
      open={cancelModalVisible}
      onOk={() => handleCancel(cancelReason)}
      okButtonProps={{ disabled: !cancelReason.reason }}
      onCancel={() => setCancelModalVisible(false)}
    >
      Why are you cancelling this appointment?
      <Form form={form} layout="vertical">
        <Form.Item>
          <Select
            value={cancelReason?.reason}
            placeholder="Select"
            onChange={(value) =>
              setCancelReason({
                ...cancelReason,
                reason: value
              })
            }
            data-testid="cancel-reason-select"
            style={{ width: '100%' }}
          >
            {cancelReasonList.length > 0 &&
              cancelReasonList.map((item, index) => (
                <Option key={item.appt_cancel_code} value={item.appt_cancel_code}>
                  {item.appt_cancel_desc}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </ConfirmationModal>
  );
}

const GrabAppointment = ({ appointment, refreshAppointments }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const grabAppointment = useGrabAppointment(appointment);

  useEffect(() => {
    const grabbingAppointment = async () => {
      if (grabAppointment) {
        const response = await grabAppointment();
        if (response === 'success') {
          setShowSuccessModal(true);
        }
      }
    };
    grabbingAppointment().then();
  }, [grabAppointment]);

  useEffect(() => {
    if (showSuccessModal) {
      successModal({
        title: 'Appointment Taken Successfully',
        content: 'The appointment has been reassigned to you',
        onOk: () => refreshAppointments()
      });
    }
  }, [showSuccessModal]);
  return <></>;
};

const TAB_KEY = 1;

const ActiveDocuments = ({ pageType }) => {
  const dispatch = useDispatch();
  const { updateAppointment } = useContext(AppointmentContext);
  const { user } = useAuth();

  const router = useRouter();

  const [viewNotesModalVisible, setViewNotesModalVisible] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelAppointmentError, setCancelAppointmentError] = useState('');
  const [notes, setNotes] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentSecurity>();
  const [appointmentToTransfer, setAppointmentTransfer] = useState({});
  const [detailPatient, setDetailPatient] = useState(false);
  const [tryAppointmentGrab, setTryAppointmentGrab] = useState(false);
  const currentBreakPoint = useWidthBreakpoint();

  const transferAppointment = (appointment) => {
    const minutesUntilStart = differenceInMinutes(new Date(appointment.datetime), new Date());
    if (minutesUntilStart < 10) {
      errorModal({
        title: 'Unable to Transfer Appointment',
        content: 'This appointment is too close to appointment start to transfer'
      });
    } else {
      confirmationModal({
        title: 'Are you sure you want to transfer this appointment?',
        content: 'The selected appointment will be assigned to you',
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          setAppointmentTransfer(appointment);
          const res = await dispatch(actions.takeAppointment(appointment.appointment_id));
          if (res.type === 'ACTION_SUCCESS') {
            setTryAppointmentGrab(true);
            refreshAppointments();
          }
          if (res?.payload?.status === 409) {
            errorModal({
              title: 'Unable to Transfer Appointment',
              content: 'There is a booking conflict between the appointment and another appointment you have scheduled.'
            });
          }
        },
        autoFocusButton: 'ok',
        closable: true,
        okButtonProps: { type: 'primary' },
        cancelButtonProps: { type: 'default' }
      });
    }
  };

  const tableActionsMenu = (val) => {
    const options = [
      {
        key: 0,
        label: 'View Notes',
        'data-testid': 'view-notes-action',
        onClick: () => onViewNotes(val.case_id)
      },
      {
        key: 1,
        label: 'View Timeline',
        'data-testid': 'view-timeline-action',
        onClick: () => {
          const team = (val.clinician_user_guid !== user.user_guid).toString();
          timelineLink(val.case_id, val.appointment_id, val.patient_user_guid, team, val.clinician_user_guid);
        }
      }
    ];
    if (val.clinician_user_guid === user.user_guid) {
      options.push(
        {
          key: 2,
          label: 'View Appointment',
          'data-testid': 'view-appointment-action',
          onClick: () =>
            startAppointment(
              val.appointment_id,
              val.call_type_code,
              val.status,
              val.appointment_type,
              val.appt_type_code
            )
        },
        {
          key: 3,
          label: 'Patient Details',
          'data-testid': 'patient-details-action',
          onClick: () => {
            getPatientInfo(val);
            setDetailPatient(true);
          }
        }
      );
    }

    if (!val.case_review && val.clinician_user_guid === user.user_guid) {
      options.push(
        {
          key: 5,
          label: 'Reschedule Appointment',
          'data-testid': 'reschedule-appointment-action',
          onClick: () => onRescheduleModal(true, val)
        },
        {
          key: 6,
          label: 'Cancel Appointment',
          'data-testid': 'cancel-appointment-action',
          onClick: () => {
            setAppointmentToCancel(val);
            if (val.appointment_outcome !== null) {
              setCancelAppointmentError('An outcome has already been recorded for this appointment.');
            } else {
              setCancelModalVisible(true);
            }
          }
        }
      );
    }

    if (val.clinician_user_guid !== user.user_guid) {
      options.push({
        key: 7,
        label: 'Transfer Appointment to Me',
        onClick: () => {
          transferAppointment(val);
        }
      });
    }

    return options;
  };

  const rawColumns = useMemo(
    () => [
      {
        title: 'Client',
        dataIndex: 'patient_client_name',
        key: 'patient_client_name',
        responsive: ['md'],
        onCell: () => ({ 'data-testid': 'client-name-cell' })
      },
      {
        title: 'Case Ref',
        display: 'Case Ref',
        dataIndex: 'client_case_id',
        dataSourceKey: 'client_case_id',
        key: 'client_case_id',
        filterable: {
          exact: false,
          queryKey: 'client_case_id'
        },
        responsive: ['md'],
        onCell: () => ({ 'data-testid': 'case-ref-cell' })
      },
      {
        title: 'Appt ID',
        display: 'Appt ID',
        dataIndex: 'appointment_id',
        dataSourceKey: 'appointment_id',
        key: 'appointment_id',
        filterable: {
          exact: false,
          queryKey: 'appt_id'
        },
        onCell: () => ({ 'data-testid': 'appointment-id-cell' })
      },
      {
        title: 'Date & Time',
        dataIndex: 'datetime',
        dateFilter: true,
        key: 'datetime',
        dataSourceKey: 'datetime',
        filterKey: 'datetime',
        filterable: {
          exact: false,
          queryKey: 'datetime',
          dateStartFilterKey: 'date_start',
          dateEndFilterKey: 'date_end'
        },
        render: (val) => <Datestamp date={val} format="Date @ Time - 03/01/2022 @ 8:57 AM" />,
        onCell: () => ({ 'data-testid': 'datetime-type-cell' })
      },
      {
        title: 'Assignment',
        dataIndex: 'assignment',
        dataSourceKey: 'assignment',
        filterKey: 'team',
        filterMultiple: false,
        responsive: ['sm'],
        filters: [{ value: true, text: 'My team' }],
        onCell: () => ({ 'data-testid': 'assignment-type-cell' })
      },
      {
        title: 'Appt Type',
        dataIndex: 'appointment_type',
        filterKey: 'appointment_type',
        responsive: ['md'],
        onCell: () => ({ 'data-testid': 'appointment-type-cell' })
      },
      {
        title: 'Call Type',
        dataIndex: 'call_type_desc',
        key: 'call_type_desc',
        responsive: ['lg'],
        onCell: () => ({ 'data-testid': 'call-type-cell' })
      },
      {
        title: 'Patient',
        display: 'Patient',
        dataIndex: 'patient',
        dataSourceKey: 'patient',
        filterKey: 'patient',
        filterable: true,
        responsive: ['sm'],
        onCell: () => ({ 'data-testid': 'patient-cell' })
      },
      {
        title: 'Clinician',
        display: 'Clinician',
        dataIndex: 'clinician',
        dataSourceKey: 'clinician',
        filterKey: 'clinician',
        filterable: true,
        responsive: ['sm'],
        onCell: () => ({ 'data-testid': 'clinician-cell' })
      },
      {
        title: 'Outcome',
        dataIndex: 'appointment_outcome',
        key: 'appointment_outcome',
        responsive: ['md'],
        onCell: () => ({ 'data-testid': 'outcome-cell' })
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        responsive: ['md'],
        onCell: () => ({ 'data-testid': 'status-cell' })
      },
      {
        title: 'Actions',
        dataIndex: '',
        key: 'x',
        render: (val, row, index) => (
          <Dropdown
            className="dropdown"
            trigger={['click']}
            menu={{ items: tableActionsMenu(val) }}
            placement="topRight"
          >
            <Button data-testid="actions-menu" type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
        onCell: () => ({ 'data-testid': 'actions-cell' })
      }
    ],
    []
  );
  const {
    columns: tableColumn,
    query,
    handleTableChange,
    pageNumber,
    setPageNumber,
    clearFilters,
    tableKey
  } = useColumnFilters(rawColumns, TAB_KEY);

  const {
    isLoading,
    page: activeFilterAP,
    lastPage,
    refetch: refreshAppointments
  } = usePagedQuery(
    actions.clinicianAppointment(
      {
        order: 'desc',
        sort: 'datetime',
        cancelled: false,
        ...query
      },
      false
    ),
    ['clinicianAppointment'],
    pageNumber,
    setPageNumber
  );

  const [displayColumnList, hideColumnList] = useReactiveColumns(tableColumn, currentBreakPoint);

  const getPatientInfo = async (val) => {
    const selectedPatient = await dispatch(actions.dataProtection(val.appointment_id));
    if (selectedPatient?.payload.length > 0) {
      setSelectedAppointment(selectedPatient?.payload[0]);
    }
  };

  const onRescheduleModal = (value, data) => {
    const appointmentUpdates = {
      appointmentId: data.appointment_id,
      clinicianId: data.clinician_user_guid,
      clinicianSpecCat: data.spec_cat_code,
      clinicianAreas: data.case_spec_area_code,
      clinicianTypeCode: data.clinician_type_code,
      caseId: data.case_id,
      userId: data.patient_user_guid,
      patientName: data.patient,
      patientSpecArea: data.case_spec_area,
      clinicianRescheduling: true,
      typeCode: data.clinician_type_code,
      appointmentType: data.appt_type_code
    };
    updateAppointment(appointmentUpdates);
    router.push(`/${pageType}/choose`);
  };

  const onCancelAppointment = async (cancelReason) => {
    const reasonResponse = await dispatch(actions.rescheduleCancel(cancelReason), false);
    if (reasonResponse.type === actionTypes.RESCHEDULE_CANCEL_SUCCESS) {
      refreshAppointments();
    } else {
      setCancelAppointmentError(
        `Unable to cancel appointment.${
          reasonResponse.error.status === 406 ? ' This appointment cannot be cancelled with your selected reason.' : ''
        }`
      );
    }
  };

  const onViewNotes = async (case_id) => {
    const notesResponse = await dispatch(actions.clinicianViewCaseNotes(case_id));
    setNotes(notesResponse.patient_notes);
    setViewNotesModalVisible(true);
  };

  const timelineLink = (req, app, guid, team, clin_guid) => {
    if (!req || !app) return;
    const reqId = Buffer.from(`${req}`).toString('base64');
    const appId = Buffer.from(`${app}`).toString('base64');
    const patient_guid = Buffer.from(`${guid}`).toString('base64');
    const clinician_user_guid = Buffer.from(`${clin_guid}`).toString('base64');

    const queryParams = { client_case_id: reqId, appt_id: appId, patient_guid, team, clinician_user_guid };
    const query = qs.stringify(queryParams);
    const url = `/${pageType}/timeline360?${query}`;

    router.push(url).then();
  };

  const isNotOkayToStartAppointment = (id, callType, status, appointment_type, appt_type_code) => {
    return (
      !id ||
      !status ||
      (appointment_type !== 'Case Review' &&
        appointment_type !== 'Case Review (PIFU)' &&
        !callType &&
        appt_type_code !== 'CASEREVF' &&
        appt_type_code !== 'CASEREVPIFU' &&
        appt_type_code !== 'CASEREV2NDOPIN')
    );
  };

  const startAppointment = (id, callType, status, appointment_type, appt_type_code) => {
    if (isNotOkayToStartAppointment(id, callType, status, appointment_type, appt_type_code)) return;

    const encryptid = Buffer.from(`${id}`).toString('base64');
    const apptCompleted = status === 'Completed';
    router.push(`/${pageType}/session?req=${encryptid}&on=${callType}&status=${apptCompleted}`);
  };

  const RowStyle = styled.div`
    display: flex;
    justify-content: end;
    align-items: baseline;
    padding: 4px 0;
    line-height: 1;
  `;

  return (
    <>
      <RowStyle>
        <Button type="primary" onClick={clearFilters} data-testid="reset-cases-filters">
          Clear Filters
        </Button>
      </RowStyle>
      <Table
        key={tableKey}
        rowKey="appointment_id"
        columns={displayColumnList}
        dataSource={activeFilterAP}
        size="middle"
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <Card style={{ width: 'auto' }}>
              {' '}
              {hideColumnList.map((item, index) => {
                return (
                  <p key={record[item.dataIndex]}>
                    <b>{item.title} :</b>{' '}
                    {item.dataIndex === 'datetime' ? (
                      <Datestamp date={record[item.dataIndex]} format="Date only - 03/01/2022" />
                    ) : (
                      record[item.dataIndex]
                    )}
                  </p>
                );
              })}
            </Card>
          ),
          rowExpandable: (record) => {
            return hideColumnList.length > 0;
          }
        }}
        locale={{
          emptyText: isLoading ? (
            <div style={{ height: '44px' }}>
              <Spin
                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
              />
            </div>
          ) : null
        }}
        onChange={handleTableChange}
      />
      <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} isLastPage={lastPage} />
      <CancelAppointmentModal
        appointment={appointmentToCancel}
        onCancelAppointment={onCancelAppointment}
        onAppointmentCancelError={setCancelAppointmentError}
        cancelModalVisible={cancelModalVisible}
        setCancelModalVisible={setCancelModalVisible}
      />

      <InfoModal
        title="View Notes"
        data-testid="view-notes-modal"
        open={viewNotesModalVisible}
        onOk={() => setViewNotesModalVisible(false)}
        onCancel={() => setViewNotesModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {notes?.length > 0 ? (
          notes.map((item, index) => (
            <Descriptions key={index + 1 /* keeps SonarQube happy */} column={1}>
              <Descriptions.Item label="Added by">{item.added_by}</Descriptions.Item>
              <Descriptions.Item label="Date & Time Added">
                <Datestamp date={item.date_added} format="Date @ Time - 03/01/2022 @ 8:57 AM" />
              </Descriptions.Item>
              <Descriptions.Item label="Notes">{item.note_text}</Descriptions.Item>
            </Descriptions>
          ))
        ) : (
          <Empty description="No notes found" />
        )}
      </InfoModal>

      <ErrorModal
        title="Could not cancel appointment"
        open={cancelAppointmentError !== ''}
        onCancel={() => setCancelAppointmentError('')}
        data-testid="dismiss"
      />

      {tryAppointmentGrab && (
        <GrabAppointment appointment={appointmentToTransfer} refreshAppointments={refreshAppointments} />
      )}

      {detailPatient && (
        <PatientInfo
          data={selectedAppointment}
          open={detailPatient}
          onOk={() => setDetailPatient(false)}
          onCancel={() => setDetailPatient(false)}
        />
      )}
    </>
  );
};

export default ActiveDocuments;
