import ServiceApi from '../services/service.api';

const baseServiceUrl = `/randox`;

type LabTest = {
  id: number;
  service_code: string;
};

export type BaseOrderParams = {
  case_id: number;
  appt_id: number;
  pat_guid: string;
  lab_tests: LabTest[];
  order_route_code: string;
};

type AdressParams = {
  addline1: string;
  addline2?: string;
  addline3?: string;
  city?: string;
  county?: string;
  postcode: string;
};

export type OrderParamsWithAddress = BaseOrderParams & AdressParams;

const ApiRandox = {
  getRandoxByID: (order_number: string, pat_user_guid: string) => {
    const params = {
      order_number,
      action: 'updateOrderStatus',
      pat_user_guid
    };
    return ServiceApi.post(`${baseServiceUrl}`, params);
  },
  assignOrderId: (order_number: string, lab_test_ids: number[], pat_user_guid: string) => {
    const params = {
      order_number,
      lab_test_ids,
      action: 'assignOrderId',
      pat_user_guid
    };
    return ServiceApi.post(`${baseServiceUrl}`, params);
  },
  createStoolOrder: async (params: OrderParamsWithAddress) => {
    const body = {
      action: 'createStoolOrder',
      ...params
    };
    return ServiceApi.post(`${baseServiceUrl}`, body);
  },
  createBloodRandoxOrder: async (params: BaseOrderParams) => {
    const body = {
      action: 'createBloodRandoxOrder',
      ...params
    };
    return ServiceApi.post(`${baseServiceUrl}`, body);
  },
  createBloodInuviOrder: async (params: OrderParamsWithAddress) => {
    const body = {
      action: 'createBloodInuviOrder',
      ...params
    };
    return ServiceApi.post(`${baseServiceUrl}`, body);
  }
};

export default ApiRandox;
