import * as z from 'zod';
import { Options } from './helpers/type-helpers';
import { axiosInstance, toastOnError } from '@vl-core/api';
import { safeParse } from './helpers/safeParse';

export const path = '/meta/users/APPUSER';

const TypeRoleSchema = z.object({
  type_code: z.string(),
  role_code: z.string(),
  role_desc: z.string()
});
type TypeRole = {
  type_code: string;
  role_code: string;
  role_desc: string;
};

const TypeSchema = z.object({
  type_code: z.string(),
  type_desc: z.string()
});
type Type = {
  type_code: string;
  type_desc: string;
};

export const UsersMetaSchema = z.object({
  titles: z.array(
    z.object({
      title: z.string()
    })
  ),
  sexes: z.array(
    z.object({
      sex_code: z.string(),
      sex_desc: z.string()
    })
  ),
  types: z.array(TypeSchema),
  admin_types: z.array(TypeSchema).nullable(),
  admin_type_roles: z
    .array(
      z.object({
        user_type_code: z.string(),
        user_type_desc: z.string(),
        user_role_code: z.string(),
        user_role_desc: z.string()
      })
    )
    .nullable(),
  subtypes: z.array(
    z.object({
      subtype_code: z.string(),
      subtype_desc: z.string()
    })
  ),
  type_roles: z.array(TypeRoleSchema),
  ref_type_roles: z.array(TypeRoleSchema).nullable(),
  ref_user_types: z.array(TypeSchema).nullable(),
  clin_type_roles: z.array(TypeRoleSchema).nullable(),
  hbs_type_roles: z.array(TypeRoleSchema).nullable(),
  identity_providers: z.array(
    z.object({
      ident_prov_code: z.string(),
      ident_prov_desc: z.string()
    })
  ),
  geo_regions: z.array(
    z.object({
      geo_region: z.string()
    })
  )
});

export type UsersMeta = {
  titles: {
    title: string;
  }[];
  sexes: {
    sex_code: string;
    sex_desc: string;
  }[];
  types: Type[];
  admin_types: Type[] | null;
  admin_type_roles:
    | {
        user_type_code: string;
        user_type_desc: string;
        user_role_code: string;
        user_role_desc: string;
      }[]
    | null;
  subtypes: {
    subtype_code: string;
    subtype_desc: string;
  }[];
  type_roles: TypeRole[];
  ref_type_roles: TypeRole[] | null;
  ref_user_types: Type[] | null;
  clin_type_roles: TypeRole[] | null;
  hbs_type_roles: TypeRole[] | null;
  identity_providers: {
    ident_prov_code: string;
    ident_prov_desc: string;
  }[];
  geo_regions: {
    geo_region: string;
  }[];
};

export const ResponseSchema = z.object({
  meta: z.array(UsersMetaSchema)
});
export type Response = {
  meta: UsersMeta[];
};

export const getMetaUsers = async (): Promise<UsersMeta[]> =>
  axiosInstance.get(path).then((res) => safeParse(res.data, z.array(UsersMetaSchema)));

export const getMetaUsersQuery = (options?: Options) =>
  ({
    queryKey: [path],
    queryFn: () => getMetaUsers(),
    select: (data: UsersMeta[]) => data[0],
    throwOnError: options?.skipToast ? false : toastOnError,
    staleTime: Infinity
  }) as const;

// Tests
let rs = {} as z.infer<typeof ResponseSchema>;
let rt = {} as Response;

rs = rt;
rt = rs;
